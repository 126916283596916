import React from 'react'
import Tooltip from 'components/Tooltip'
import { kebabCase } from 'lib/utils'
import Link from 'components/Link'
import { Pineapple, Pizza } from 'components/NotProductIcons'
import ReactCountryFlag from 'react-country-flag'
import { ContributorImage } from 'components/PostLayout/Contributors'
import { getImage } from 'gatsby-plugin-image'
import SidebarSection from 'components/PostLayout/SidebarSection'

import { ThumbDown, ThumbUp } from 'components/Icons/Icons'

interface ITeam {
    frontmatter: {
        headshot: string
        name: string
        country: string
        jobTitle: string
        pineappleOnPizza: boolean
    }
}

interface ISidebarProps {
    team?: ITeam[]
    teamLead?: ITeam
    teamName?: string
    teamSlug: string
}

export const pineappleText = (percentage: number) => {
    if (percentage === 50) return 'This team is evenly split on whether pineapple belongs on pizza'
    if (percentage < 50) return 'Shockingly, this team prefers their pizza without pineapple'
    return (
        <>
            <strong>{percentage}%</strong> of this team prefer pineapple on pizza
        </>
    )
}

export default function Sidebar({ team, teamLead, teamName, teamSlug }: ISidebarProps) {
    const teamLength = team?.length
    if (!team || !teamLength) return null
    const teamLeadHeadshotImage = teamLead?.frontmatter?.headshot || teamLead?.parent?.cover?.image
    const pineapplePercentage =
        teamLength &&
        teamLength > 0 &&
        Math.round((team.filter(({ frontmatter: { pineappleOnPizza } }) => pineappleOnPizza).length / teamLength) * 100)
    return (
        <>
            <SidebarSection title="Meet your team" className="-mt-2">
                <h3 className="m-0 mb-2 text-sm font-semibold">
                    <Link
                        to={teamSlug}
                        className="group relative -mx-2 -my-1 flex w-full items-center justify-between rounded p-2 leading-none
                    hover:bg-gray-accent/50
                    active:top-[0.5px]
                    active:scale-[.98]"
                    >
                        <span>Team {teamName}</span>
                        <span className="text-lg leading-none text-black/30 opacity-0 group-hover:opacity-100">
                            &rarr;
                        </span>
                    </Link>
                </h3>
                <ul className="m-0 flex list-none flex-wrap p-0">
                    {team.map(({ frontmatter, parent }) => {
                        return (
                            <li
                                key={frontmatter?.name}
                                className="relative -ml-2 mb-1 rounded-full transition-all first:-ml-0 hover:z-20 hover:scale-[1.2] active:top-[.5px] active:scale-[1.15]
                                [&:nth-child(4n+1)]:bg-orange
                                [&:nth-child(4n+2)]:bg-blue
                                [&:nth-child(4n+3)]:bg-red
                                [&:nth-child(4n+4)]:bg-yellow
                                "
                            >
                                <Link
                                    to={`/team#${
                                        kebabCase(frontmatter?.name) + '-' + kebabCase(frontmatter?.jobTitle)
                                    }`}
                                >
                                    <Tooltip
                                        placement="top-end"
                                        className="whitespace-nowrap"
                                        content={() => (
                                            <div className="flex items-center space-x-1">
                                                <span className="text-xs">{frontmatter?.name}</span>
                                                <span className="flex w-[14px]">
                                                    <ReactCountryFlag
                                                        width={14}
                                                        svg
                                                        countryCode={frontmatter?.country}
                                                    />
                                                </span>
                                            </div>
                                        )}
                                    >
                                        <span className="relative">
                                            <ContributorImage
                                                name={frontmatter?.name}
                                                image={
                                                    (frontmatter?.headshot || parent?.cover?.image) &&
                                                    getImage(frontmatter?.headshot || parent?.cover?.image)
                                                }
                                                className="!h-10 !w-10 border-[2.5px] border-solid border-white dark:border-primary"
                                                imgClassName=""
                                            />
                                        </span>
                                    </Tooltip>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </SidebarSection>

            {teamLead && (
                <SidebarSection title="Team lead" className="-mt-2">
                    <Link
                        to={`/team#${
                            kebabCase(teamLead?.frontmatter?.name) + '-' + kebabCase(teamLead?.frontmatter?.jobTitle)
                        }`}
                        className="relative -mx-1 -mt-1 flex items-center space-x-2 rounded p-1
                    hover:bg-gray-accent/50
                    active:top-[0.5px]
                    active:scale-[.98]"
                    >
                        <ContributorImage
                            className="h-[40px] w-[40px] border-2 border-solid border-white bg-orange dark:border-primary"
                            image={teamLeadHeadshotImage && getImage(teamLeadHeadshotImage)}
                            name={teamLead?.frontmatter?.name}
                        />
                        <p className="author m-0 text-base text-[15px] font-semibold">{teamLead?.frontmatter?.name}</p>
                        <ReactCountryFlag svg countryCode={teamLead?.frontmatter?.country} />
                    </Link>
                </SidebarSection>
            )}

            {/* <SidebarSection title="Pineapple on pizza?" className="-mt-2">
                <div className="space-x-2 flex items-center mb-4 text-lg font-semibold">
                    <span className="w-8 h-8 relative top-[-1px] -mr-1">
                        <Pineapple />
                    </span>
                    <span>+</span>
                    <span className="w-7 h-7 relative top-[1px]">
                        <Pizza />
                    </span>
                    <span>=</span>
                    {pineapplePercentage >= 50 ? (
                        <ThumbUp className="w-8 h-8 fill-green" />
                    ) : (
                        <ThumbDown className="w-8 h-8 fill-green" />
                    )}
                </div>
                <p className="text-sm -mt-1 opacity-70 leading-tight mb-3">{pineappleText(pineapplePercentage)}</p>
                <div className="h-2 w-full bg-white dark:bg-gray-accent-dark rounded-md relative overflow-hidden">
                    <div
                        style={{ width: `${pineapplePercentage}%` }}
                        className={`${pineapplePercentage >= 50 ? 'bg-[#6AC17B]' : 'bg-red'} absolute inset-0 h-full`}
                    />
                </div>
            </SidebarSection> */}
        </>
    )
}
