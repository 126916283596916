import Layout from 'components/Layout'
import PostLayout from 'components/PostLayout'
import { SEO } from 'components/seo'
import { SEO2 } from 'components/seo2'
import { graphql } from 'gatsby'
import React from 'react'
import Link from 'components/Link'
import { CompensationCalculator } from 'components/CompensationCalculator'
import InterviewProcess from 'components/Job/InterviewProcess'
import Apply from 'components/Job/Apply'
import Sidebar from 'components/Job/Sidebar'
import { sfBenchmark } from 'components/CompensationCalculator/compensation_data/sf_benchmark'
import { benefits } from 'components/Careers/Benefits'
import { Department, Location, Timezone } from 'components/NotProductIcons'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Heading } from 'components/Heading'
import TutorialsSlider from 'components/TutorialsSlider'
import { MdxCodeBlock } from 'components/CodeBlock'
import { shortcodes } from '../mdxGlobalComponents'
import { ZoomImage } from 'components/ZoomImage'
import { Blockquote } from 'components/BlockQuote'
import { InlineCode } from 'components/InlineCode'

const A = (props) => <Link {...props} className="font-semibold text-red hover:text-red" />

const Detail = ({ icon, title, value }: { icon: React.ReactNode; title: string; value: string }) => {
    return (
        <li className="flex space-x-2">
            <span className="h-6 w-6  dark:text-white">{icon}</span>
            <span className="grid">
                <h4 className="m-0 pt-1 text-sm font-normal leading-none">
                    <span>{title}</span>
                </h4>
                <p className="m-0 mt-1 text-[15px]">
                    <strong className=" dark:text-white">{value}</strong>
                </p>
            </span>
        </li>
    )
}

const Accordion = ({ title, children, id }: { title: string; children: React.ReactNode; id: string }) => {
    return (
        <details open>
            <summary>
                <h2 id={id}>{title}</h2>
            </summary>
            {children}
        </details>
    )
}

const GithubIssuesSections = ({
    gitHubIssuesObject,
}: {
    gitHubIssuesObject: { url: string; title: string; labels: { name: string; url: string }[]; number: number }[]
}) => {
    return (
        <Accordion title="Typical tasks" id="typical-tasks">
            <div className="mb-2">
                <p>Here are some open GitHub issues you could help solve:</p>
                <ul className="!m-0 grid list-none gap-y-4 p-0">
                    {gitHubIssuesObject?.length > 0 &&
                        gitHubIssuesObject?.map(({ url, number, title, labels }) => {
                            return (
                                <li key={title} className="flex flex-col md:flex-row md:items-center">
                                    <div className="flex space-x-2">
                                        <Link to={url} className="block w-[60px] md:w-auto">
                                            <span className="font-code text-sm  font-semibold hover:text-black/75 dark:text-white/50 dark:hover:text-white/75">
                                                #{number}
                                            </span>
                                        </Link>
                                        <Link to={url}>{title}</Link>
                                    </div>
                                    {labels && labels?.length > 0 && (
                                        <ul className="!ml-[calc(60px+.25rem)] !mt-0 !mb-0 flex list-none items-center space-x-1 p-0 md:!ml-2">
                                            {labels?.map(({ name, url }, index) => {
                                                return (
                                                    <li key={name + index}>
                                                        <Link
                                                            className="rounded-sm bg-blue/10 py-1 px-[5px] text-sm text-blue transition-all hover:bg-blue/20 hover:text-blue dark:bg-white/10 dark:text-white/75 dark:hover:bg-white/30 dark:hover:text-white/100"
                                                            to={url}
                                                        >
                                                            {name}
                                                        </Link>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    )}
                                </li>
                            )
                        })}
                </ul>
            </div>
        </Accordion>
    )
}

const ObjectivesSection = ({ mission, objectives }) => {
    return (
        <Accordion title="Your team's mission and objectives" id="mission-objectives">
            <div className="mb-6">
                <MDXProvider components={{ HideFromJobPosting: () => null }}>
                    <MDXRenderer>{mission?.body}</MDXRenderer>
                </MDXProvider>
                <MDXProvider components={{ HideFromJobPosting: () => null }}>
                    <MDXRenderer>{objectives?.body}</MDXRenderer>
                </MDXProvider>
            </div>
        </Accordion>
    )
}

const ApplySection = ({ id, info, zohoId }) => {
    return (
        <Accordion title="Apply" id="apply">
            <div className="mb-6">
                <Apply id={id} info={info} zohoId={zohoId} />
            </div>
        </Accordion>
    )
}

const HtmlSection = ({ html }) => {
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: html,
            }}
        />
    )
}
const InterviewProcessSection = ({ role }: { role: string }) => {
    return (
        <Accordion title="Interview process" id="interview-process">
            <div className="mb-6">
                <InterviewProcess role={role} />
            </div>
        </Accordion>
    )
}

const SfBenchmarkSection = ({ salaryRole }: { salaryRole: string }) => {
    return (
        <Accordion title="Salary" id="salary">
            <p>
                We have a set system for compensation as part of being transparent. Salary varies based on location and
                level of experience.
            </p>
            <p>
                <Link to="/howwework/people/compensation">Learn more about compensation</Link>
            </p>
            <div className="mb-6">
                <CompensationCalculator
                    descriptions={{
                        step: `We hire into the Established step by default and believe there's a place to have incremental steps to allow for more flexibility.`,
                        location: `The benchmark for each role we are hiring for is based on the market rate in San Francisco.`,
                        level: `We pay more experienced team members a greater amount since it is reasonable to expect this correlates with an increase in skill`,
                    }}
                    hideFormula
                    hideRole
                    initialJob={salaryRole}
                />
            </div>
        </Accordion>
    )
}
const BenefitsSection = ({
    benefitsObject = benefits,
}: {
    benefitsObject: { title: string; image: React.HTMLImageElement }[]
}) => {
    return (
        <Accordion title="Benefits" id="benefits">
            <ul className="m-0 grid list-none grid-cols-1 gap-6 p-0 pb-4 md:grid-cols-2">
                {benefitsObject?.length > 0 &&
                    benefitsObject?.map(({ title, image }) => {
                        return (
                            <li
                                key={title}
                                className="flex items-center space-x-4 text-[15px] font-medium leading-tight"
                            >
                                <img className="max-w-[30px]" alt={title} src={image} />
                                <span>{title}</span>
                            </li>
                        )
                    })}
            </ul>
            <p>
                Get more details about all our benefits on the <Link to="/careers#benefits">Careers page</Link>.
            </p>
        </Accordion>
    )
}

// export const Head = ({ location, params, data: { icloudreadyJobPosting }, pageContext: { teamName } }) => (
//     <SEO
//         title={icloudreadyJobPosting?.frontmatter?.title + ''}
//         description={icloudreadyJobPosting?.frontmatter?.description || icloudreadyJobPosting?.excerpt}
//         image={`/og-images/${icloudreadyJobPosting?.fields?.slug?.replace(/\//g, '')}.jpeg`}
//     />
// )

export default function GoogleDocsJob(pageProps) {
    const { data, location, pageContext, params, children } = pageProps
    const { team, teamLead, teamInfo, objectives, mission, allJobPostings } = data

    const departmentName = data?.icloudreadyJobPosting?.frontmatter?.departmentName
    const zohoId = data?.icloudreadyJobPosting?.frontmatter?.zohoId
    const info = data?.icloudreadyJobPosting?.frontmatter?.info
    const id = data?.icloudreadyJobPosting?.id
    const customFields = data?.icloudreadyJobPosting?.frontmatter?.customFields
    const body = data?.icloudreadyJobPosting?.body
    const locationName = data?.icloudreadyJobPosting?.frontmatter?.locationName
    const jobTimezone = data?.icloudreadyJobPosting?.frontmatter?.jobTimezone
    const salaryRoleName = data?.icloudreadyJobPosting?.frontmatter?.salaryRoleName
    const showMissionAndObjectives = data?.icloudreadyJobPosting?.frontmatter?.showMissionAndObjectives
    const parent = data?.icloudreadyJobPosting?.parent
    const tableOfContents = pageContext?.tableOfContents
    const breadcrumb = pageContext?.breadcrumb
    const menu = pageContext?.menu
    const breadcrumbBase = pageContext?.breadcrumbBase
    const html = data?.icloudreadyJobPosting?.frontmatter?.html
    const title = data?.icloudreadyJobPosting?.frontmatter?.title
    const slug = data?.icloudreadyJobPosting?.frontmatter?.slug
    const gitHubIssues = pageContext?.gitHubIssues
    const teamName = pageContext?.teamName
    const timezone = jobTimezone || customFields?.find(({ title }) => title === 'Timezone(s)')?.value
    const salaryRole = salaryRoleName || customFields?.find(({ title }) => title === 'Salary')?.value || title
    const missionAndObjectives =
        showMissionAndObjectives || customFields?.find(({ title }) => title === 'Mission & objectives')?.value
    const showObjectives = missionAndObjectives !== 'false'
    const defaultMenu = [
        {
            name: 'Work at iCloudReady',
        },
        {
            name: 'Careers home',
            url: '/careers',
        },
        {
            name: 'About us',
            url: '/about-us',
        },
        {
            name: 'Our story',
            url: '/how-we-work/company/story',
        },
        {
            name: 'Open roles',
            url: '',
            children:
                allJobPostings?.nodes?.length > 0 &&
                allJobPostings?.nodes?.map((node) => {
                    return {
                        name: node?.frontmatter?.title,
                        url: node?.frontmatter?.slug,
                    }
                }),
        },
    ]

    const components = {
        ...shortcodes(pageProps),
        h1: (props) => Heading({ as: 'h1', ...props }),
        h2: (props) => Heading({ as: 'h2', ...props }),
        h3: (props) => Heading({ as: 'h3', ...props }),
        h4: (props) => Heading({ as: 'h4', ...props }),
        h5: (props) => Heading({ as: 'h5', ...props }),
        h6: (props) => Heading({ as: 'h6', ...props }),
        pre: MdxCodeBlock,
        inlineCode: InlineCode,
        blockquote: Blockquote,
        img: ZoomImage,
        video: (props) => (
            <ZoomImage>
                <video {...props} />
            </ZoomImage>
        ),
        a: A,
        TutorialsSlider,
    }
    return (
        <Layout>
            <div className="border-t border-dashed border-gray-accent-light dark:border-gray-accent-dark">
                <PostLayout
                    title="careers"
                    tableOfContents={[
                        ...tableOfContents,
                        { ...(sfBenchmark[salaryRole] ? { value: 'Salary', url: 'salary', depth: 0 } : {}) },
                        { value: 'Benefits', url: 'benefits', depth: 0 },
                        {
                            ...(gitHubIssues?.length > 0
                                ? { value: 'Typical tasks', url: 'typical-tasks', depth: 0 }
                                : {}),
                        },
                        {
                            ...(showObjectives && objectives
                                ? { value: "Your team's mission and objectives", url: 'mission-objectives', depth: 0 }
                                : {}),
                        },
                        { value: 'Interview process', url: 'interview-process', depth: 0 },
                        { value: 'Apply', url: 'apply', depth: 0 },
                    ]}
                    hideSearch
                    hideSurvey
                    sidebar={
                        <Sidebar
                            teamSlug={teamInfo?.fields?.frontmatter || teamInfo?.fields?.slug}
                            teamName={teamName}
                            team={team?.nodes}
                            teamLead={teamLead}
                        />
                    }
                    menu={defaultMenu || menu}
                >
                    <div className="relative">
                        <div>
                            <h1 className="m-0 text-5xl">{title}</h1>
                            <ul className="m-0 mt-6 flex list-none  flex-col space-y-6 p-0 dark:text-white/50 md:flex-row md:items-center md:space-x-12 md:space-y-0">
                                <Detail title="Department" value={departmentName} icon={<Department />} />
                                <Detail title="Location" value={locationName} icon={<Location />} />
                                {timezone && <Detail title="Timezone(s)" value={timezone} icon={<Timezone />} />}
                            </ul>
                            <div className="job-content mt-12 w-full flex-shrink-0 transition-all">
                                <div className="article-content">
                                    <MDXProvider components={components}>
                                        <MDXRenderer>{body}</MDXRenderer>
                                    </MDXProvider>
                                </div>
                                {sfBenchmark[salaryRole] && <SfBenchmarkSection salaryRole={salaryRole} />}
                                {benefits?.length > 0 && <BenefitsSection benefitsObject={benefits} />}
                                {gitHubIssues?.length > 0 && <GithubIssuesSections gitHubIssuesObject={gitHubIssues} />}
                                {showObjectives && objectives && (
                                    <ObjectivesSection objectives={objectives} mission={mission} />
                                )}
                                <InterviewProcessSection role={title} />
                                <ApplySection id={id} info={info} zohoId={zohoId} />
                            </div>
                        </div>
                    </div>
                </PostLayout>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query GoogleDocsJobQuery(
        $id: String!
        $teamName: String!
        $teamNameInfo: String!
        $objectives: String!
        $mission: String!
    ) {
        teamLead: mdx(frontmatter: { team: { in: [$teamName] }, teamLead: { in: [$teamName] } }) {
            id
            frontmatter {
                name
                country
                jobTitle
                headshot {
                    id
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            parent {
                ... on GoogleDocs {
                    cover {
                        image {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
        team: allMdx(filter: { frontmatter: { team: { in: [$teamName] } } }) {
            nodes {
                id
                frontmatter {
                    name
                    country
                    jobTitle
                    pineappleOnPizza
                    headshot {
                        id
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
                parent {
                    ... on GoogleDocs {
                        cover {
                            image {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
        icloudreadyJobPosting: mdx(id: { eq: $id }) {
            id
            body
            excerpt
            fields {
                slug
                pageViews
            }
            frontmatter {
                title
                slug
                departmentName
                zohoId
                salaryRoleName
                jobTimezone
                showMissionAndObjectives
                teamName
                employmentType
                publishedDate: date
                locationName
                info: jobInfo {
                    descriptionHtml
                    applicationFormDefinition {
                        sections {
                            fields {
                                isRequired
                                descriptionPlain
                                field {
                                    type
                                    title
                                    isNullable
                                    path
                                    selectableValues {
                                        label
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
                customFields {
                    value
                    title
                }
            }
            parent {
                ... on GoogleDocs {
                    breadcrumb {
                        name
                        url: slug
                    }
                    cover {
                        image {
                            publicURL
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
        allJobPostings: allMdx(
            filter: {
                frontmatter: { slug: { regex: "/careers/" } }
                parent: { internal: { type: { eq: "GoogleDocs" } } }
            }
        ) {
            nodes {
                jobId: id
                frontmatter {
                    slug
                    title
                    fields: customFields {
                        title
                        value
                    }
                }
            }
        }
        teamInfo: mdx(frontmatter: { title: { eq: $teamNameInfo } }) {
            fields {
                slug
            }
            frontmatter {
                slug
            }
        }
        objectives: mdx(fields: { slug: { eq: $objectives } }) {
            body
        }
        mission: mdx(fields: { slug: { eq: $mission } }) {
            body
        }
    }
`
